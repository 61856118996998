import { Component, OnInit, Input } from "@angular/core";
import { IReportEmbedConfiguration, models } from "powerbi-client";
import { PowerBiService } from "src/app/shared/services/power-bi.service";

@Component({
  selector: "app-power-bi-module",
  templateUrl: "./power-bi-module.component.html",
  styleUrls: ["./power-bi-module.component.scss"],
})
export class PowerBiModuleComponent implements OnInit {
  @Input() page: any;
  @Input() filter: any;
  reportClass = 'report-container';
  reportModel: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  insightModel = this.reportModel;

  constructor(private readonly powerBiService: PowerBiService) {}

  ngOnInit(): void {
    this.getPowerBIReportDetails();
  }
  async getPowerBIReportDetails() {
    this.powerBiService
      .getPowerBIReportDetails(this.page)
      .subscribe((res: any) => {
        const report = res[0];
        this.loadPowerBIReports(report);
      });
  }
  
  loadPowerBIReports(report:any){
    const basicFilter: models.IBasicFilter = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table: '', column: ''},
      operator: 'All',
      values: [],
      filterType: models.FilterType.Basic,
      requireSingleSelection: true,
      displaySettings: {
        /** Hiding filter pane */
        isLockedInViewMode: true,
        isHiddenInViewMode: true,
      },
    };
    if (this.filter !== undefined){
      basicFilter.target = this.filter.target;
      basicFilter.operator = this.filter.operator;
      basicFilter.values = this.filter.values;
    }
   
    this.powerBiService.getTokenForBI().subscribe((res: any) => {
          this.powerBiService.getBIEmbedURL(res.access_token,report?.report_id).subscribe((reportData: any) => {
            this.powerBiService.getBIEmbedToken(res.access_token,report?.report_id,report?.group).subscribe((tokenData: any) => {
              this.insightModel = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,report?.report_name);
            })
        });
    });
  }

  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      pageName,
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: 0.6,
        background: models.BackgroundType.Transparent,
      },
    };
  }
}
