import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoggingService } from "src/app/logging.service";

@Injectable({
  providedIn: "root",
})
export class ScorecardService {
  warningText = "Unexpected value";

  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService
  ) {}
  private readonly messageSource = new BehaviorSubject<any>([]);
  cardDetails = this.messageSource.asObservable();

  private readonly okrSource = new BehaviorSubject<any>([]);
  okrDetails$ = this.okrSource.asObservable();

  private readonly riskSource = new BehaviorSubject<any>([]);
  riskDetails$ = this.riskSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  okrSpread(data: any) {
    this.okrSource.next(data);
  }

  riskSpread(data: any) {
    this.riskSource.next(data);
  }

  getUpcomingReleases() {
    return this.http.get("projects/releases/upcoming");
  }

  getRecentReleases() {
    return this.http.get("projects/releases?");
  }

  getFilteredUpcomingReleases(params: string) {
    return this.http.get(`projects/releases/upcoming?${params}`);
  }

  getFilteredRecentReleases(params: string) {
    return this.http.get(`projects/releases?${params}`);
  }

  getRiskDetails(filterString = "") {
    return this.http.get("risk/project-risks/portfolios/?" + filterString);
  }

  getFilteredRiskDetails(
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    projectId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (vendorId) {
      qprm += "&vendor=" + vendorId;
    } else if (projectId) {
      qprm = "&project=" + projectId;
    } else if (programId) {
      qprm = "&program=" + programId;
    } else if (subportfolioId) {
      qprm = "&subportfolio=" + subportfolioId;
    } else if (portfolioId) {
      qprm = "&portfolio=" + portfolioId;
    } else {
      this.loggingService.warn("Unexpected value");
    }
    return this.http.get(`risk/project-risks/portfolios/?${qprm}`);
  }

  getWhatIfData(metricId: any) {
    return this.http.get(`metrics/derived-metrics/?metric=${metricId}`);
  }

  getMetricsList() {
    return this.http.get("metrics/independent-matric/");
  }

  getPortfolioInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `risk/project-risks/subportfolios/${portfolioId}/?risk=${riskId}`
    );
  }

  getGoalInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `risk/project-risks/goals/${portfolioId}/?risk=${riskId}`
    );
  }

  predictMetricModelReverse(modelId: number, params: any) {
    return this.http.get(`ml_whatif/models/${modelId}/predict-reverse`, {
      params,
    });
  }
  predictMetricModel(modelId: number, params: any) {
    return this.http.get(`ml_whatif/models/${modelId}/predict`, { params });
  }

  getWhatIfModelData(id: number, params: any) {
    return this.http.get(`ml_whatif/models/${id}/linked-metrics`, { params });
  }

  getWidgets(
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm += "&project_id=" + projectId;
    } else if (programId) {
      qprm += "&program_id=" + programId;
    } else if (subportfolioId) {
      qprm += "&portfolio_id=" + subportfolioId;
    } else if (portfolioId) {
      qprm += "&portfolio_id=" + portfolioId;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return this.http.get(`widget/widgets/?${qprm}`);
  }

  createWidget(payload: any) {
    return this.http.post(`widget/widgets/`, payload);
  }

  getMetrics() {
    return this.http.get("metrics/metric-master/all/");
  }

  getWidget(widgetId: number) {
    return this.http.get(`widget/widgets/${widgetId}/`);
  }

  updateWidget(widgetId: number, payload: any) {
    return this.http.patch(`widget/widgets/${widgetId}`, payload);
  }

  deleteWidget(widgetId: number) {
    return this.http.delete(`widget/widgets/${widgetId}`);
  }

  getWidgetTrend(widgetId: number, qParam: any) {
    return this.http.get(`widget/widgets/${widgetId}/metric-data/?${qParam}`);
  }

  getRecommendedWidgets(projectId: any) {
    return this.http.get(`projects/${projectId}/recommended-widgets/`);
  }

  cloneWidget(payload: any) {
    return this.http.post(`projects/create-widget/`, payload);
  }

  addProjectLevelMetrics(payload: any) {
    return this.http.post("projects/metrics/", payload);
  }
}
