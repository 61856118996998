import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CreatePulseDialogComponent } from "src/app/pages/kepler/hubble/create-pulse-dialog/create-pulse-dialog.component";
import { SurveyService } from "../../services/survey.service";
import { DeletePopupComponent } from "../delete-popup/delete-popup.component";
import { ToastrService } from "ngx-toastr";
import { FinancialService } from "../../services/financial.service";
import { ProjectService } from "../../services/project.service";
import { RiskConvergenceService } from "../../services/risk-convergence.service";

@Component({
  selector: "app-pulse",
  templateUrl: "./pulse.component.html",
  styleUrls: ["./pulse.component.scss"],
})
export class PulseComponent {
  @Input() pulsesList: any = null;
  @Input() projectId: any = null;
  @Input() card: any = null;
  @Input() isRisk: any = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly surveyService: SurveyService,
    private readonly toastrService: ToastrService,
    private readonly financialService: FinancialService,
    private readonly projectService: ProjectService,
    private readonly riskConvergenceService: RiskConvergenceService
  ) {}

  getGMapRandomColor() {
    const colorNum = 360;
    const color = Math.floor(Math.random() * colorNum);
    return `hsla(${color}, 50%, 25%, 90%)`;
  }

  getPerformanceImpact(pulse: any) {
    let perfImpact = "";
    pulse.performance_impact.forEach((impact: any) => {
      perfImpact += impact.display_name + ", ";
    });
    perfImpact = perfImpact.trimEnd();
    if (perfImpact.endsWith(",")) {
      perfImpact = perfImpact.slice(0, -1);
    }
    return perfImpact;
  }

  getPulse() {
    if (this.projectId) {
      this.financialService
        .getProjectPulse(this.projectId)
        .subscribe((res: any) => {
          this.pulsesList = res;
          this.pulsesList.forEach((pulse: any) => {
            pulse["color"] = this.getGMapRandomColor();
            pulse["impact"] = this.getPerformanceImpact(pulse);
          });
        });
    } else if (this.card) {
      this.projectService.getCardPulse(this.card).subscribe((res: any) => {
        this.pulsesList = res;
        this.pulsesList.forEach((pulse: any) => {
          pulse["color"] = this.getGMapRandomColor();
          pulse["impact"] = this.getPerformanceImpact(pulse);
        });
      });
    } else if (this.isRisk) {
      this.riskConvergenceService.getRiskPulse().subscribe((res: any) => {
        this.pulsesList = res;
        this.pulsesList.forEach((pulse: any) => {
          pulse["color"] = this.getGMapRandomColor();
          pulse["impact"] = this.getPerformanceImpact(pulse);
        });
      });
    } else {
      this.surveyService.getPulse().subscribe((res: any) => {
        this.pulsesList = res;
        this.pulsesList.forEach((pulse: any) => {
          pulse["color"] = this.getGMapRandomColor();
          pulse["impact"] = this.getPerformanceImpact(pulse);
        });
      });
    }
  }

  deletePulse(id: any, name: string) {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      data: name,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.surveyService.deletePulse(id).subscribe(() => {
          this.toastrService.success("Template deleted successfully !");
          this.getPulse();
        });
      }
    });
  }

  showEditPulseDialog(pulse: any) {
    const dialog = this.dialog.open(CreatePulseDialogComponent, {
      width: "500px",
      data: {
        pulseId: pulse.id,
      },
    });
    dialog.afterClosed().subscribe((data: any) => {
      if (data) {
        setTimeout(() => {
          this.getPulse();
        }, 1000);
      }
    });
  }
}
