import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-widget-metric-dialog",
  templateUrl: "./widget-metric-dialog.component.html",
  styleUrls: ["./widget-metric-dialog.component.scss"],
})
export class WidgetMetricDialogComponent implements OnInit {
  metricForm = new FormGroup({
    metrics: new FormControl(""),
  });
  metricsList = [];
  constructor(
    public dialogRef: MatDialogRef<WidgetMetricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.metricsList = this.data.metricsList;
    this.metricForm.patchValue({ metrics: this.data.selectedMetrics || [] });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addMetrics() {
    this.dialogRef.close(this.metricForm.value);
  }
}
