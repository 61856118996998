import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import { of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class WorkForceService {
  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  getAttritionData(projectId?: any, offSet?: any, end?: any, month?: any) {
    let params = `offset=${offSet}&end=${end}`;

    if (month) {
      params = `month=${month}&${params}`;
    }
    if (projectId) {
      params = `project=${projectId}&${params}`;
    }

    return this.http.get(`workforce/attritions/?${params}`);
  }

  getOpenRequirmentMasterData(offSet?: any, end?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.get(`workforce/open-reqs/?${params}`);
  }

  getProjectOpenRequirmentMasterData(projectId: any, offSet?: any, end?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.get(
      `workforce/open-reqs/projects/${projectId}/?${params}`
    );
  }

  updateProjectApplicationMaster(projectId: any, payload: any) {
    return this.http.put(
      `master/application/project-application/${projectId}/`,
      payload
    );
  }
  updateProjectOpenRequirmentMaster(projectId: any, payload: any) {
    return this.http.put(`workforce/open-reqs/projects/${projectId}/`, payload);
  }

  getWorkForceDataById(id: number) {
    return this.http.get(`workforce/resources/?id=${id}`);
  }

  getAllDropDownValues() {
    const statusKey = makeStateKey("resources-dropdown");
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http
        .get(`workforce/resources-dropdown/`)
        .pipe(tap((res: any) => this.transferState.set(statusKey, res)));
    }
    return of(cachedResponse);
  }

  postWorkForceById(id: number, payload: any) {
    return this.http.put(`workforce/edit-resource/${id}/`, payload, {
      observe: "response",
    });
  }

  saveNewInsightsData(payload: any) {
    return this.http.post(`powerbi/powerbi/create-powerbi-list/`, payload);
  }
  getInsightsList() {
    return this.http.get(`powerbi/powerbi/get-powerbi-list/`);
  }
  deleteInsightData(id: any) {
    return this.http.delete(`powerbi/powerbi/delete-powerbi-list/${id}/`);
  }

  getProjectKKRList(
    projectId: any,
    offSet?: any,
    end?: any,
    filterString?: any
  ) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.get(
      `workforce/krrs/?project=${projectId}&${params}&${filterString}`
    );
  }
  getProjectResourceList(
    projectId: any,
    offSet?: any,
    end?: any,
    filterString?: any
  ) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.get(
      `workforce/resources/?project=${projectId}&${params}&${filterString}`
    );
  }
  getProjectAttritionList(
    projectId: any,
    offSet?: any,
    end?: any,
    filterString?: any
  ) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.get(
      `workforce/attritions/?project=${projectId}&${params}&${filterString}`
    );
  }

  getResourceList(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/resources/?${params}`, payload);
  }
  getKKRList(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/krrs/?${params}`, payload);
  }
  getAttritionList(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/attritions/?${params}`, payload);
  }

  getProjectResourceListNew(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/resources/?${params}`, payload);
  }

  getProjectAttritionListNew(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/attritions/?${params}`, payload);
  }

  geProjectKKRListNew(offSet?: any, end?: any, payload?: any) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(`workforce/krrs/?${params}`, payload);
  }

  getProjectApplicationMasterDataNew(
    projectId?: any,
    offSet?: any,
    end?: any,
    payload?: any
  ) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(
      `master/application/project-application/${projectId}/?${params}`,
      payload
    );
  }
  getApplicationMasterDataNew(
    projectId?: any,
    offSet?: any,
    end?: any,
    payload?: any
  ) {
    const params = `offset=${offSet}&end=${end}`;
    return this.http.post(
      `master/application/application-master/${projectId}/?${params}`,
      payload
    );
  }

  getWorkForceAttritionDataById(id: number) {
    return this.http.post(`workforce/attritions/?id=${id}`, "");
  }

  getWorkForceResourceDataById(id: number, projectId: any = "") {
    let queryParam = `id=${id}`;
    if (projectId) {
      queryParam = `id=${id}&project_id=${projectId}`;
    }
    return this.http.post(`workforce/resources/?${queryParam}`, "");
  }

  getWorkForceKrrDataById(id: number) {
    return this.http.post(`workforce/krrs/?id=${id}`, "");
  }

  updateWorkForceDetailsById(payload: any, id: any) {
    return this.http.put(`workforce/${id}/`, payload);
  }

  updatedExitStatus(payload: any, id: any) {
    return this.http.put(`workforce/update_resignation_date/${id}/`, payload);
  }

  updateResourceContract(id: any, payload: any) {
    return this.http.patch(`workforce/${id}/`, payload);
  }

  downloadPeopleInsights(url: any, payload: any) {
    return this.http.post(url, payload, { responseType: "blob" });
  }
  getBrowseAIData(query: any) {
    return this.http.get(`browseai/parameterized-search/?key=${query}`);
  }

  getProjectVendorContractList(payload: any) {
    const params = new HttpParams()
      .set("project_id", payload?.project_id)
      .set("subportfolio_name", payload?.subportfolio_name)
      .set("portfolio_name", payload?.portfolio_name)
      .set("program_name", payload?.program_name)
      .set("company_name", payload?.vendor);
    return this.http.get(`projects/contracts/available/`, {
      params,
    });
  }

  getProjectTrainings(projectId: any) {
    return this.http.get(
      `workforce/project-trainings/?project_id=${projectId}`
    );
  }

  deleteResourceTraining(resTrainingId: any) {
    return this.http.delete(`workforce/resource-trainings/${resTrainingId}/`);
  }

  getmetric() {
    return this.http.get(`metrics/metric-value/metrics`);
  }

  getContractTypes() {
    return this.http.get(`base_value/?type=workforce_contract_type`);
  }
}
