import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoggingService } from "src/app/logging.service";

@Injectable({
  providedIn: "root",
})
export class ScorecardService {
  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService
  ) {}
  private readonly messageSource = new BehaviorSubject<any>([]);
  cardDetails = this.messageSource.asObservable();

  private readonly okrSource = new BehaviorSubject<any>([]);
  okrDetails$ = this.okrSource.asObservable();

  private readonly riskSource = new BehaviorSubject<any>([]);
  riskDetails$ = this.riskSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  okrSpread(data: any) {
    this.okrSource.next(data);
  }

  riskSpread(data: any) {
    this.riskSource.next(data);
  }

  getUpcomingReleases() {
    return this.http.get("projects/releases/upcoming");
  }

  getRecentReleases() {
    return this.http.get("projects/releases?");
  }

  getFilteredUpcomingReleases(params: string) {
    return this.http.get(`projects/releases/upcoming?${params}`);
  }

  getFilteredRecentReleases(params: string) {
    return this.http.get(`projects/releases?${params}`);
  }

  getRiskDetails(filterString = "") {
    return this.http.get("risk/project-risks/portfolios/?" + filterString);
  }

  getFilteredRiskDetails(
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (vendorId) {
      qprm += "&vendor=" + vendorId;
    } else if (programId) {
      qprm = "&program=" + programId;
    } else if (subportfolioId) {
      qprm = "&subportfolio=" + subportfolioId;
    } else if (portfolioId) {
      qprm = "&portfolio=" + portfolioId;
    } else {
      this.loggingService.warn("Unexpected value");
    }
    return this.http.get(`risk/project-risks/portfolios/?${qprm}`);
  }

  getWhatIfData(metricId: any) {
    return this.http.get(`metrics/derived-metrics/?metric=${metricId}`);
  }

  getMetricsList() {
    return this.http.get("metrics/independent-matric/");
  }

  getPortfolioInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `risk/project-risks/subportfolios/${portfolioId}/?risk=${riskId}`
    );
  }

  getGoalInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `risk/project-risks/goals/${portfolioId}/?risk=${riskId}`
    );
  }
}
