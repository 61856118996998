import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  constructor(private readonly http: HttpClient) {}

  getFinanceBudget(projectId: any) {
    const params = `project_id=${projectId}`;
    return this.http.get(`finance/budgets/?${params}`);
  }

  getFinanceSpend(projectId: any, month: any = "") {
    const params = `project_id=${projectId}&month=${month}`;
    return this.http.get(`finance/spends/?${params}`);
  }

  getCostPoolTaxanomy() {
    return this.http.get("metrics/cost-pool-taxonomy/details/");
  }

  getOuGdpList() {
    return this.http.get("vendors/names/");
  }

  getUserList() {
    return this.http.get("users/user-list/");
  }

  getExecutionTypeList() {
    return this.http.get("base_value/?type=project_execution_type");
  }

  getSpendTypes() {
    return this.http.get("base_value/?type=project_spend_type");
  }

  addFinanceBudget(data: any) {
    return this.http.post("finance/budgets/", data);
  }

  editFinanceBudget(budgetId: any, data: any) {
    return this.http.patch(`finance/budgets/${budgetId}/`, data);
  }

  editProjectDetail(projectId: any, data: any) {
    return this.http.patch(`projects/${projectId}/`, data);
  }

  deleteFinanceBudget(budgetId: any) {
    return this.http.delete(`finance/budgets/${budgetId}/`);
  }

  addFinanceSpend(data: any) {
    return this.http.post("finance/spends/", data);
  }

  editFinanceSpend(spendId: any, data: any) {
    return this.http.patch(`finance/spends/${spendId}/`, data);
  }

  deleteFinanceSpend(spendId: any) {
    return this.http.delete(`finance/spends/${spendId}/`);
  }

  getProjectPulse(projectId: any) {
    return this.http.get(`nps/pulses/?project=${projectId}`);
  }
}
