import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TagsService } from "../../services/tag.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { MessageService } from "../../services/message.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagComponent implements AfterViewInit, OnChanges {
  constructor(
    private readonly tagService: TagsService,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService,
    private readonly cookieService: CookieService
  ) {}

  @Input() associatedTags: any = [];
  @Input() tagsIds: any = [];
  @Input() dataObj: any;
  @Input() noIds = false;
  @Input() slices = 0;
  @Input() vendorEdit = false;
  @Input() isReadOnly = false;
  @Input() isImport = false;
  @Output() childTags: EventEmitter<any> = new EventEmitter();
  @Output() removeTags: EventEmitter<any> = new EventEmitter();
  allTags: any = [];
  tagInput = "";
  filteredList: any = [];
  filteredCategoryList: any = [];

  tagCategories: any[] = [];

  public linkIndex = -1;

  selectedCategory: any;
  count = 0;
  user: any;

  ngAfterViewInit() {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.getTags();
    if (!this.cookieService.get("tags")) {
      const myDate: Date = new Date();
      myDate.setSeconds(myDate.getSeconds() + 10);

      this.cookieService.set("tags", "true", { expires: myDate });
      this.getAllTags();
    }
    this.getSlice();
  }

  ngOnChanges(): void {
    if (this.isImport) {
      this.associatedTags = [];
    }
    if (this.tagsIds?.length && this.allTags.length) {
      this.tagsIds.forEach((tag: any) => {
        let tagId = tag;
        if (tag.id) {
          tagId = tag.id;
        }
        if (this.associatedTags.findIndex((e: any) => e.id === tagId) === -1) {
          this.associatedTags.push(
            this.allTags[this.allTags.findIndex((e: any) => e.id === tagId)]
          );
        }
      });
    }
  }

  async getTags() {
    this.messageService.getTags().subscribe((res: any) => {
      if (res.length > 0) {
        this.allTags = [];
        this.associatedTags = [];
        this.filteredList = [];
        this.formatTags(res);
      }
    });
  }

  getAllTags() {
    this.tagService.getTags().subscribe((res: any) => {
      this.messageService.setTags(res);
    });
  }

  formatTags(res: any) {
    this.allTags = res;
    this.getAllDistinctCategories(this.allTags);
    this.filteredList = this.filterTagsBssedOnSelectedCategory();
    this.tagsIds?.forEach((element: any) => {
      const tagIdx = this.allTags.findIndex((e: any) => e.id === element);
      if (tagIdx > -1) {
        this.associatedTags.push({
          id: element,
          tag_name: this.allTags[tagIdx].tag_name,
          tag_category: this.allTags[tagIdx].tag_category,
        });
      }
    });
  }

  getSlice() {
    if (this.slices === 0) {
      this.count = this.associatedTags.length;
    } else {
      this.count = this.slices;
    }
    return this.count;
  }

  getAllDistinctCategories(data: any[]) {
    const tagCategoriesTemp: any[] = [];
    data.filter(function (item) {
      const i = tagCategoriesTemp.findIndex(
        (x) => x.tag_category === item.tag_category
      );
      if (i <= -1) {
        tagCategoriesTemp.push(item);
      }
      return null;
    });
    this.tagCategories = tagCategoriesTemp;
    if (this.tagInput) {
      this.searchInput(this.tagInput);
    } else {
      this.filteredCategoryList = this.tagCategories;
    }
  }

  checkSelected(item: any) {
    if (item) {
      return !!this.associatedTags.find(
        (e: any) =>
          e.tag_name === item.tag_name && e.tag_category === item.tag_category
      );
    }
    return false;
  }

  public searchInput(val: any) {
    const filterValue = val.toLowerCase();

    this.filteredCategoryList = this.tagCategories.filter(
      (option: any) =>
        option.tag_category.toLowerCase().indexOf(filterValue) === 0
    );
  }

  navigateUsingKey(event: any) {
    const downTraversal = 38;
    const upTraversal = 40;
    switch (event.keyCode) {
      case downTraversal:
        if (this.linkIndex === -1) {
          this.linkIndex = 0;
        } else {
          this.linkIndex--;
        }
        this.downTraverse(this.filteredList.length);
        break;

      case upTraversal:
        this.upTraverse(this.filteredList.length);
        this.linkIndex++;
        break;
      default:
        break;
    }
  }

  selectCategory(selectedCat: any) {
    if (
      !this.selectedCategory ||
      selectedCat.tag_category !== this.selectedCategory.tag_category
    ) {
      this.selectedCategory = selectedCat;
      this.filterTagsBssedOnSelectedCategory();
    } else {
      this.selectedCategory = null;
    }
  }
  filterTagsBssedOnSelectedCategory() {
    if (this.selectedCategory) {
      this.filteredList = this.allTags.filter(
        (option: any) =>
          option.tag_category
            .toLowerCase()
            .indexOf(this.selectedCategory.tag_category.toLowerCase()) > -1
      );
    } else {
      this.filteredList = [];
    }
  }

  downTraverse(listLength: number) {
    if (this.linkIndex === -1) {
      this.linkIndex = listLength - 1;
    }
  }

  upTraverse(listLength: number) {
    if (this.linkIndex >= listLength - 1) {
      this.linkIndex = -1;
    }
  }

  public addTag(tag: any) {
    if (this.checkSelected(tag)) {
      this.removeTag(tag);
    } else {
      let newObj = {};

      if (tag || this.linkIndex > -1) {
        let id;
        if (tag.id) {
          id = tag.id;
        } else {
          id = this.filteredList[this.linkIndex].id;
        }
        newObj = {
          tag_id: id,
        };
      } else {
        let tagCat = "default";
        if (this.selectedCategory) {
          tagCat = this.selectedCategory.tag_category;
        }
        newObj = {
          tag_name: this.tagInput,
          tag_category: tagCat,
        };
        this.tagInput = "";
        this.searchInput(this.tagInput);
      }
      newObj = { ...this.dataObj, ...newObj };
      this.mapTag(newObj);
    }
  }

  public stopPropagation(event: any) {
    event.stopPropagation();
  }

  removeTag(tag: any) {
    if (!this.noIds) {
      let newObj = { id: tag.id };
      newObj = { ...this.dataObj, ...newObj };
      this.tagService.removeTag(newObj).subscribe((res: any) => {
        if (res.message === "Entity tag removed successfully") {
          this.associatedTags.splice(
            this.associatedTags.findIndex((e: any) => e.id === tag.id),
            1
          );
        }
      });
    } else {
      this.associatedTags.splice(
        this.associatedTags.findIndex((e: any) => e.id === tag.id),
        1
      );
    }
    this.removeTags.emit(tag);
    this.getSlice();
  }

  deleteTag(tag: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message:
          "Removing the tags will result in their deletion throughout the entire application.",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.tagService.deleteTag(tag.id).subscribe(() => {
          this.associatedTags.splice(
            this.associatedTags.findIndex((e: any) => e.id === tag.id),
            1
          );
          this.allTags.splice(
            this.allTags.findIndex((e: any) => e.id === tag.id),
            1
          );
          this.filteredList = this.filterTagsBssedOnSelectedCategory();
          this.getSlice();
        });
      }
    });
  }

  mapTag(data: any) {
    if (!this.noIds) {
      this.tagService.magTag(data).subscribe((res: any) => {
        if (!data.tag_id) {
          this.allTags.push({
            id: res.tag,
            tag_name: data.tag_name,
            tag_category: data.tag_category,
          });
          this.filterTagsBssedOnSelectedCategory();

          this.associatedTags.push({
            id: res.tag,
            tag_name: data.tag_name,
            tag_category: data.tag_category,
          });
        } else {
          this.associatedTags.push(
            this.allTags[
              this.allTags.findIndex((e: any) => e.id === data.tag_id)
            ]
          );
        }
      });
    } else if (!data.tag_id) {
      this.tagService.createTag(data).subscribe((res: any) => {
        this.allTags.push({
          id: res.id,
          tag_name: data.tag_name,
          tag_category: data.tag_category,
        });
        this.associatedTags.push({
          id: res.id,
          tag_name: data.tag_name,
          tag_category: data.tag_category,
        });
        this.childTags.emit(this.associatedTags);
      });
    } else {
      this.associatedTags.push(
        this.allTags[this.allTags.findIndex((e: any) => e.id === data.tag_id)]
      );
      this.childTags.emit(this.associatedTags);
    }
    this.getSlice();
  }
}
