import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { ProgramService } from "../../services/program.service";
import textConfiguration from "src/assets/static-text-configuration.json";
import { NgxSpinnerService } from "ngx-spinner";
import { LoggingService } from "src/app/logging.service";

@Component({
  selector: "app-left-side-menu",
  templateUrl: "./left-side-menu.component.html",
  styleUrls: ["./left-side-menu.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LeftSideMenuComponent implements OnInit, OnDestroy {
  opportunityText: any = (textConfiguration as any).opportunity;
  toeText: any = (textConfiguration as any).toe;
  rfxText: any = (textConfiguration as any).rfx;
  proposalText: any = (textConfiguration as any).proposal;
  opportunityId: any;
  tips = true;
  showIdea = false;
  convertedID = "";
  oppCurrentStatus: any = {};
  percent = 0;
  colorCode = "#FFC000";
  isRedirectFrom = false;
  projectID: any = "";
  sub: any;
  firstSepperLable = this.opportunityText?.create_edit;
  warningText = "Unexpected value";
  @ViewChild("stepper") stepper: MatStepper;
  stepName = localStorage.getItem("stepLabel");
  opportunityDetails: any;
  user: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private readonly ps: ProgramService,
    private readonly spinner: NgxSpinnerService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.sub = this.route.queryParams.subscribe((params: any) => {
      this.isRedirectFrom = params["redirectFrom"] || false;
      this.projectID = params["id"];
    });
    this.route.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.convertedID = res.id;
      this.getOpportunityById();
    });
  }

  getOpportunityById() {
    this.ps.getOppStatus(this.opportunityId).subscribe((res: any) => {
      this.opportunityDetails = res;
      this.oppCurrentStatus = this.opportunityDetails?.status;
      this.ps.setStatusValue(this.oppCurrentStatus);
      if (this.oppCurrentStatus?.slug === "opportunity_draft") {
        this.percent = 20;
        this.colorCode = "#dd2c54";
      } else if (
        this.oppCurrentStatus?.slug === "opportunity_created" ||
        this.oppCurrentStatus?.slug === "opportunity_modified"
      ) {
        this.percent = 40;
        this.colorCode = "#daa479";
      } else if (this.oppCurrentStatus?.slug === "opportunity_published") {
        this.percent = 50;
        this.colorCode = "#1a85d6";
      } else if (this.oppCurrentStatus?.slug === "opportunity_evaluating") {
        this.percent = 75;
        this.colorCode = "#FFC000";
      } else if (this.oppCurrentStatus?.slug === "opportunity_sow_signed") {
        this.percent = 100;
        this.colorCode = "#07b797";
      } else if (this.oppCurrentStatus?.slug === "opportunity_cancelled") {
        this.percent = 100;
        this.colorCode = "#dd2c54";
      } else {
        this.loggingService.warn(this.warningText);
      }
    });
  }

  ngAfterViewInit() {
    this.setStepperData();
    this.ps.getStatusValue().subscribe((newValue) => {
      if (newValue?.slug !== this.oppCurrentStatus?.slug) {
        this.oppCurrentStatus = newValue;
        if (newValue?.slug === "opportunity_draft") {
          this.percent = 20;
          this.colorCode = "#dd2c54";
        } else if (
          newValue?.slug === "opportunity_created" ||
          newValue?.slug === "opportunity_modified"
        ) {
          this.percent = 40;
          this.colorCode = "#daa479";
        } else if (newValue?.slug === "opportunity_published") {
          this.percent = 50;
          this.colorCode = "#1a85d6";
        } else if (newValue?.slug === "opportunity_evaluating") {
          this.percent = 75;
          this.colorCode = "#FFC000";
        } else if (newValue?.slug === "opportunity_sow_signed") {
          this.percent = 100;
          this.colorCode = "#07b797";
        } else if (newValue?.slug === "opportunity_cancelled") {
          this.percent = 100;
          this.colorCode = "#dd2c54";
        } else {
          this.loggingService.warn(this.warningText);
        }
      }
    });
  }
  setStepperData() {
    const lastUrlSegment = this.router.url.split("/").pop();
    const zeroIndexItems: any = [
      "setup",
      "scope",
      "terms",
      "more-info",
      "preview",
      "evaluation",
    ];
    if (
      lastUrlSegment === "proposal-evaluation" ||
      lastUrlSegment === "summary" ||
      lastUrlSegment === "award-deal"
    ) {
      if (this.user?.is_vendor) {
        this.stepper.selectedIndex = 0;
      } else {
        this.stepper.selectedIndex = 1;
      }
      this.firstSepperLable = this.opportunityText?.edit;
    } else if (zeroIndexItems.indexOf(lastUrlSegment) > -1) {
      this.stepper.selectedIndex = 0;
    } else {
      this.loggingService.warn(this.warningText);
    }
    this.cdRef.detectChanges();
  }

  clickDeliveryContract() {
    this.router.navigate([`toe/${btoa(this.opportunityId)}/delivery-contract`]);
  }

  clickAddcontract() {
    this.router.navigate([`toe/${btoa(this.opportunityId)}/add-terms`]);
  }

  navigateTO(page: string) {
    this.ps.getOppStatus(this.opportunityId).subscribe(
      (res: any) => {
        this.ps.setStatusValue(res?.status);
        if (this.isRedirectFrom) {
          this.router.navigate(
            [`/rfx/${this.convertedID}/opportunity/${page}`],
            { queryParams: { redirectFrom: "Project", id: this.projectID } }
          );
        } else {
          this.router.navigate([
            `/rfx/${this.convertedID}/opportunity/${page}`,
          ]);
        }
      },
      () => this.spinner.hide()
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
