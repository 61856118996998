<app-modal-header-common title="Add Metrics"> </app-modal-header-common>
<div class="plan-form">
  <form [formGroup]="metricForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Metrics</mat-label>
      <mat-select formControlName="metrics" multiple>
        <ng-container *ngFor="let opt of metricsList">
          <mat-option [value]="opt.id">
            {{ opt.display_name
            }}{{
              opt.metric_unit_name ? " (" + opt.metric_unit_name + ")" : ""
            }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div style="display: flex; justify-content: end" class="metric-btns">
      <button
        (click)="closeDialog()"
        mat-button
        mat-dialog-close
        color="primary"
        class="mx-1"
      >
        Close
      </button>
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="addMetrics()"
      >
        Save
      </button>
    </div>
  </form>
</div>
