import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ProjectService } from "src/app/shared/services/project.service";
import { NgxSpinnerService } from "ngx-spinner";
import textConfiguration from "src/assets/static-text-configuration.json";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import { models } from "powerbi-client";
import { PowerBiService } from "src/app/shared/services/power-bi.service";
import { MatSlider } from "@angular/material/slider";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { WhatIfComponent } from "src/app/pages/kepler/what-if/what-if.component";
import { PulsesDialogComponent } from "src/app/pages/delivery-functionality/pulses-dialog/pulses-dialog.component";
import { CommonService } from "src/app/shared/services/common.service";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import Highcharts from "highcharts";
import noData from "highcharts/modules/no-data-to-display";
import { takeUntil, tap } from "rxjs/operators";
import { LoggingService } from "src/app/logging.service";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  constructDataObject,
  getActualValue,
  formatNumber,
  getChange,
  getChartData,
  getDisplayLables,
  getPulseCounts,
  initializeMetrics,
  intializeData,
  loadLinkedMatricsData,
  loadPerformanceBenchMarcData,
  loadPowerBIReport,
  processActualValues,
  pushDataToRelevantType,
  reloadMetricDataOnOverview,
  reqAgingGraph,
  reqAgingGraphOverview,
  returnSparkLineChart,
  returnSparkLineChartOverView,
  roundToTwoDecimals,
  saveSortedMatricsData,
  getTrendChartData,
  getPulsesInfo,
  getGMapRandomColor,
  getScoreCardQuadrantData,
  getProjectMetrics,
} from "./utils/utils";
import { MessageService } from "src/app/shared/services/message.service";
import { CreatePulseDialogComponent } from "src/app/pages/kepler/hubble/create-pulse-dialog/create-pulse-dialog.component";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { WidgetMetricDialogComponent } from "../widget-metric-dialog/widget-metric-dialog.component";
import { ToastrService } from "ngx-toastr";
import { DeletePopupComponent } from "../../delete-popup/delete-popup.component";
import { DatePipe } from "@angular/common";
noData(Highcharts);
@Component({
  selector: "app-scorecard-dashboard",
  templateUrl: "./scorecard.component.html",
  styleUrls: ["./scorecard.component.scss"],
  providers: [DatePipe],
})
export class ScorecardComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @ViewChild("slider") public slider: MatSlider;
  @Input() projectId: string;
  @Input() pageType: string;
  displayLabels: any;
  deliveryText: any = (textConfiguration as any).delivery;
  commonText: any = (textConfiguration as any).common;
  staticText: any = (textConfiguration as any).common;
  balanceScorecardText: any = (textConfiguration as any).balance_scorecard;
  headerText: string = this.deliveryText?.left_bar.performance_dashboard;
  noDataCustomer = false;
  noDataPeople = false;
  noDataFinance = false;
  noDataDelivery = false;
  matrixOverView = false;
  cardMatric: any;
  whatIfValues: any;
  reportClass = "report-container";
  quadrants: any = {
    customer: "",
    finance: "",
    engineering: "",
    people: "",
  };
  customerData: any;
  financeData: any;
  engineerData: any;
  peopleData: any;
  dragPeopleEnableFlag: any[] = [];
  dragEnggEnableFlag: any[] = [];
  dragFinanceEnableFlag: any[] = [];
  dragCustomerEnableFlag: any[] = [];
  performanceBenchMark: any;
  reportModel: any = {
    type: "report",
    embedUrl: null,
    tokenType: models.TokenType.Embed,
    accessToken: null,
    settings: null,
  };
  reportConfig = this.reportModel;
  zoomLevel: any;
  linkedMatrics: any = [];
  filterObj: any;
  portfolioId: any = null;
  programId: any = null;
  subportfolioId: any = null;
  vendorId: any = null;
  benchaMarkType: any = "Portfolio";
  setMetricOverviewDetails: any;
  infoQuadrantName: any;
  quadrantInfoDetails: any;
  user: any;
  currentMetricId: any;
  pulseCounts: any;
  dataLoaded = false;
  pulseInfoLoaded = false;
  pulsesList: any;
  activeTab: any = 0;
  tableColumns: any = ["Group", "Question", "Responses"];
  auditTableColumns: any = [
    "Audit name",
    "Owner",
    "Total no. of Observations",
    "Responses",
  ];
  currentAssessment: any;
  currentAudit: any;
  currentGovAssessment: any;
  assessmentsList: any = [];
  auditsList: any = [];
  govAssessmentsList: any = [];
  auditInstanceInfo: any;
  govAggregateData: any;
  assessmentAggregateData: any;
  additionalInfoLoaded = false;
  enggId = 139;
  peopleId = 137;
  financeId = 138;
  customerId = 140;
  headerInfo = "";
  currentDate = new Date();
  iframeEmbedded = false;
  pulsesInfo: any = [];
  firstTimeLoad = true;
  pulseTableColumns: any = [
    "#",
    "Name",
    "Description",
    "Portfolio",
    "Subportfolio",
    "Program",
    "Project",
    "Vendor",
    "Action",
  ];
  MIN_ITEMS = 5;
  SMALL_BATCH = 10;
  MEDIUM_BATCH = 20;
  LARGE_BATCH = 50;
  XL_BATCH = 100;
  XXL_BATCH = 150;
  MAX_ITEMS = 200;
  sortOpt: any = [
    this.MIN_ITEMS,
    this.SMALL_BATCH,
    this.MEDIUM_BATCH,
    this.LARGE_BATCH,
    this.XL_BATCH,
    this.XXL_BATCH,
    this.MAX_ITEMS,
  ];
  sortOptions: any;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  endValue: any = 20;
  startValue: any = 0;
  widgetsLoaded = false;
  isCreateWidget = false;
  widgetsList: any = [];
  widgetCategories = ["Portfolio", "Sub Portfolio", "Program", "Project"];
  widgetCreateForm: any = this.fb.group({
    name: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
  });
  metricsList: any = [];
  selectedMetrics: any = [];
  metricsTableColumns = ["Metric", "Unit", "Type", "Function", "Action"];
  isWidgetInfo = false;
  currentWidget: any;
  frequencyList = [
    { name: "Daily", value: "daily" },
    { name: "Weekly", value: "weekly" },
    { name: "Monthly", value: "monthly" },
  ];
  frequency: any;
  showLineChart = false;
  showTrendView = true;
  potData = [];
  potTableColumns = ["Metric Name", "Current Value"];
  trendTableColumns: any = [];
  performanceWidgetFilterForm = this.fb.group({
    frequency: [null],
    startDate: [null],
    endDate: [null],
  });
  widgetTrendData: any;
  trendChartOptions: any;
  isWidgetEditMode = false;
  showRecommendedWidgets = false;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly commonService: CommonService,
    private readonly spinner: NgxSpinnerService,
    private readonly projectService: ProjectService,
    private readonly scorecardService: ScorecardService,
    private readonly powerBiService: PowerBiService,
    private readonly filterService: FilterService,
    private readonly dialog: MatDialog,
    private readonly incidentTrackerService: IncidentTrackerService,
    private readonly governanceLighthouseService: GovernanceLighthouseService,
    private readonly assessmentCentralService: AssessmentCentralService,
    private readonly loggingService: LoggingService,
    private readonly messageService: MessageService,
    private readonly toastrService: ToastrService
  ) {
    this.scorecardService.changeMessage("nodata");
    this.loadMetricValueOnclick();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object";
    });
    getDisplayLables.bind(this)(this.commonService);
    if (this.pageType !== "projectLevel") {
      this.filterService.filterValues
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.filterObj = res;
          this.portfolioId = "";
          this.subportfolioId = "";
          this.programId = "";
          this.projectId = "";
          this.vendorId = "";
          this.headerInfo = "";
          this.filterObj?.inputs?.forEach((element: any) => {
            if (element.field === "portfolio") {
              this.portfolioId = element.value?.id;
              if (this.portfolioId) {
                this.headerInfo += element.value.display_name + " > ";
              }
            }
            if (element.field === "subportfolio") {
              this.subportfolioId = element.value?.id;
              if (this.subportfolioId) {
                this.headerInfo += element.value.display_name + " > ";
              }
            }
            if (element.field === "program") {
              this.programId = element.value?.id;
              if (this.programId) {
                this.headerInfo += element.value.display_name + " > ";
              }
            }
            if (element.field === "project") {
              this.projectId = element.value?.id;
              if (this.projectId) {
                this.headerInfo += element.value.display_name + " > ";
              }
              getPulseCounts.bind(this)(this.projectId);
            }
            if (element.field === "vendor") {
              this.vendorId = element.value?.id;
              if (this.vendorId) {
                this.headerInfo += element.value.display_name;
              }
            }
          });
          this.headerInfo = this.headerInfo.trimEnd().replace(/\s>\s*$/, "");
          if (!this.headerInfo) {
            this.headerInfo = "All of IT";
          }
          if (this.activeTab === 0) {
            getScoreCardQuadrantData.bind(this)();
          } else if (this.activeTab === 1) {
            if (!this.projectId) {
              this.showRecommendedWidgets = false;
            }
            if (this.showRecommendedWidgets) {
              this.getRecommenededWidgets();
            } else {
              this.getWidgets();
            }
          } else if (this.activeTab === 2) {
            getPulsesInfo.bind(this)();
          } else {
            this.getAssessmentsAndAuditsDetails();
          }
        });
    } else {
      getProjectMetrics.bind(this)();
      getScoreCardQuadrantData.bind(this)();
    }
    getPulseCounts.bind(this)(this.projectId);
    this.setupPerformanceWidgetFilters();
  }

  getPulse(card: any, callback: () => void) {
    let qParam = `performance_impact=${card}`;
    if (this.projectId) {
      qParam += `&project=${this.projectId}`;
    }
    this.projectService.getCardPulse(qParam).subscribe((res: any) => {
      this.pulsesList = res;
      intializeData.bind(this)();
      callback();
    });
  }

  showPulses(card: any) {
    this.getPulse(card, () => {
      const dialog = this.dialog.open(PulsesDialogComponent, {
        panelClass: "no-scroll-dialog",
        height: "auto",
        width: "auto",
        data: {
          pulsesList: this.pulsesList,
          card,
        },
      });
      dialog.afterClosed().subscribe(() => {
        this.getPulse(card, () => {});
      });
    });
  }

  metricValue: any;
  month: any;
  percentValue: any;

  dataToDelivery(type: any, res: any) {
    res.forEach((val: any) => {
      initializeMetrics.bind(this)();
      const { gdpName, gdpValue } = processActualValues.bind(this)(
        val.actual_value
      );
      const lineTrendGrph = this.getLineTrendGraph(val, gdpName, gdpValue);
      const lineTrendOverview = this.getLineTrendOverview(
        val,
        gdpName,
        gdpValue
      );
      const actualValue = getActualValue(val);
      const change = getChange(val);
      const data = constructDataObject.bind(this)(
        val,
        actualValue,
        change,
        lineTrendGrph,
        lineTrendOverview
      );
      pushDataToRelevantType.bind(this)(type, data);
    });
  }

  private getLineTrendGraph(
    val: any,
    gdpName: any[],
    gdpValue: any[],
    forPdf = false
  ): any {
    const graphWidth = 200;
    if (val.name === "req_aging") {
      return reqAgingGraph(
        graphWidth,
        100,
        val?.data?.range,
        val?.data?.value.map(roundToTwoDecimals),
        "Days"
      );
    } else if (val.name === "gdp_survey_rating") {
      return reqAgingGraph(graphWidth, 100, gdpName, gdpValue, "Avg. Score");
    } else if (val.name === "experience_junior") {
      return returnSparkLineChart(
        [
          {
            data: val?.actual_value[
              val?.actual_value.length - 1
            ]?.data?.value.map(roundToTwoDecimals),
          },
        ],
        "bar",
        val?.actual_value[val?.actual_value.length - 1]?.data?.range
      );
    } else {
      return returnSparkLineChart(
        [{ data: this.metricValue.map(roundToTwoDecimals) }],
        "line",
        this.month,
        forPdf
      );
    }
  }

  private getLineTrendOverview(val: any, gdpName: any[], gdpValue: any[]): any {
    if (val.name === "req_aging") {
      return reqAgingGraphOverview(
        val?.data?.range,
        val?.data?.value.map(roundToTwoDecimals),
        val?.display_name,
        "Days Open",
        "Count of Open Reqs"
      );
    } else if (val.name === "gdp_survey_rating") {
      return reqAgingGraphOverview(
        gdpName,
        gdpValue,
        val?.display_name,
        "GDP Name",
        "Actual Value"
      );
    } else if (val.name === "experience_junior") {
      return returnSparkLineChartOverView(
        [
          {
            data: val?.actual_value[
              val?.actual_value.length - 1
            ]?.data?.value.map(roundToTwoDecimals),
          },
        ],
        "bar",
        val?.actual_value[val?.actual_value.length - 1]?.data?.range
      );
    } else {
      return returnSparkLineChartOverView(
        [{ data: this.metricValue.map(roundToTwoDecimals) }],
        "line",
        this.month
      );
    }
  }

  drop(event: CdkDragDrop<string[]>, quadrantId: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      saveSortedMatricsData.bind(this)(event.container.data, quadrantId);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      saveSortedMatricsData.bind(this)(event.container.data, quadrantId);
    }

    if (quadrantId === this.customerId) {
      this.dragCustomerEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.financeId) {
      this.dragFinanceEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.enggId) {
      this.dragEnggEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.peopleId) {
      this.dragPeopleEnableFlag[event.previousIndex] = "true";
    }
  }

  enableDragClick(i: any, type: any) {
    if (type === this.customerId) {
      this.dragCustomerEnableFlag[i] = "false";
    }
    if (type === this.financeId) {
      this.dragFinanceEnableFlag[i] = "false";
    }
    if (type === this.enggId) {
      this.dragEnggEnableFlag[i] = "false";
    }
    if (type === this.peopleId) {
      this.dragPeopleEnableFlag[i] = "false";
    }
  }

  clickOverView(type: any, metric: any, cardDetails: any, cardName: any) {
    if (this.user?.is_vendor) {
      return;
    }
    this.whatIfValues = cardDetails;
    const data: any = {
      metric,
      cardDetails,
      cardName,
    };
    this.setMetricOverviewDetails = {
      metric_id: metric?.id,
      quadrant_id: type,
      quadrant_name: cardName,
    };
    this.scorecardService.changeMessage(data);
    this.currentMetricId = metric.id;
  }

  setBIZoomLevel() {
    const large = 1500;
    const mid = 1246;
    if (window.innerWidth > large) {
      this.zoomLevel = 0.6;
    } else if (window.innerWidth <= large && window.innerWidth > mid) {
      this.zoomLevel = 0.5;
    } else if (window.innerWidth < mid) {
      this.zoomLevel = 0.4;
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }

  loadMetricValueOnclick() {
    this.scorecardService.cardDetails.subscribe((cards: any) => {
      if (cards?.cardName) {
        this.matrixOverView = true;
        this.cardMatric = cards?.metric;
        loadPowerBIReport.bind(this)(cards?.metric, models);
        loadLinkedMatricsData.bind(this)(cards?.metric?.id);
        loadPerformanceBenchMarcData.bind(this)(cards?.metric?.id);
      } else {
        this.matrixOverView = false;
      }
    });
  }

  closeMatrixOverview() {
    this.scorecardService.changeMessage("nodata");
    this.matrixOverView = false;
    getScoreCardQuadrantData.bind(this)();
  }

  getDifference(itemActualValue: any, metricActualValue: any) {
    let val: any = itemActualValue - metricActualValue;
    val = parseFloat(val).toFixed(2);
    return val || 0;
  }

  quadrantInfo(type: any, modal: any, quadrantName: any) {
    this.infoQuadrantName = quadrantName;
    this.dialog.open(modal, {
      width: "auto",
      height: "auto",
    });
    this.spinner.show();
    this.projectService.quadrantIfo(type).subscribe((res: any) => {
      this.spinner.hide();
      this.quadrantInfoDetails = res;
    });
  }

  dialogClose() {
    this.dialog.closeAll();
  }

  formatNumber(number: any, decimalPlaces: number): string {
    return formatNumber(number, decimalPlaces);
  }

  openWhatIfModel() {
    this.dialog.open(WhatIfComponent, {
      width: "auto",
      minWidth: "60vw",
      height: "auto",
      data: {
        metricId: this.currentMetricId,
        projectId: this.projectId,
      },
    });
  }

  getContribution(item: any, cardMatricValue: any) {
    let currentItemValue = 0;
    const actualVal =
      item?.actual_value[item?.actual_value.length - 1]?.actual_value;
    if (actualVal) {
      currentItemValue = actualVal;
    }
    const contri = (currentItemValue / cardMatricValue) * 100;
    return roundToTwoDecimals(contri);
  }

  getAssessmentsList() {
    this.projectService
      .getAssessments(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.assessmentsList = resp.records;
        this.currentAssessment = this.assessmentsList[0]?.id;
        if (this.currentAssessment) {
          this.getAssessmentAggregateUserResponse();
        }
      });
  }

  getAuditsList() {
    this.projectService
      .getAudits(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.auditsList = resp.records;
        this.currentAudit = "";
        this.getAuditInstanceInfo();
      });
  }

  getGovAssessmentsList() {
    this.projectService
      .getGovAssessments(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.govAssessmentsList = resp.records;
        this.currentGovAssessment = this.govAssessmentsList[0]?.id;
        if (this.currentGovAssessment) {
          this.getGovAggregateUserResponse();
        }
      });
  }

  getAssessmentsAndAuditsDetails() {
    this.govAggregateData = null;
    this.assessmentAggregateData = null;
    this.auditInstanceInfo = null;
    this.getAssessmentsList();
    this.getAuditsList();
    this.getGovAssessmentsList();
  }

  getRecords() {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage, 10) - 1) * parseInt(this.sortRecords, 10);
    } else {
      this.startValue = 0;
    }
    this.endValue =
      parseInt(this.startValue, 10) + parseInt(this.sortRecords, 10);
    if (!this.firstTimeLoad) {
      getPulsesInfo.bind(this)();
    }
    this.firstTimeLoad = false;
  }

  addBgColorToWidgets() {
    this.widgetsList.forEach((element: any) => {
      element.color = getGMapRandomColor();
    });
  }

  getWidgets() {
    this.widgetsLoaded = false;
    this.scorecardService
      .getWidgets(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId
      )
      .subscribe((res: any) => {
        this.widgetsLoaded = true;
        this.widgetsList = res;
        this.addBgColorToWidgets();
      });
  }

  changeTab(event: any) {
    this.activeTab = event.index;
    if (this.activeTab === 0) {
      if (this.pageType === "projectLevel") {
        getProjectMetrics.bind(this)();
      }
    } else if (this.activeTab === 1) {
      this.initPerformanceWidgets();
      this.getWidgets();
    } else if (this.activeTab === 2) {
      getPulsesInfo.bind(this)();
    } else if (this.activeTab === 3) {
      this.getAssessmentsAndAuditsDetails();
    }
  }

  downloadEvidence(instanceId: any, evidenceMetadata: any) {
    this.incidentTrackerService.downloadEvidence(instanceId).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        const fileName = evidenceMetadata[0];
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error: any) => {
        throw error;
      }
    );
  }

  getAuditInstanceInfo() {
    this.additionalInfoLoaded = false;
    this.incidentTrackerService
      .getSeverityReport(this.currentAudit)
      .subscribe((resp: any) => {
        this.additionalInfoLoaded = true;
        this.auditInstanceInfo = resp;
        this.auditInstanceInfo.forEach((item: any) => {
          item["chart"] = getChartData.bind(this)(item.analytics, true);
        });
      });
  }

  getGovAggregateUserResponse() {
    this.additionalInfoLoaded = false;
    this.governanceLighthouseService
      .getAggregateResponseDetails(this.currentGovAssessment)
      .subscribe((response: any) => {
        this.additionalInfoLoaded = true;
        this.govAggregateData = response.group_wise_data;
        this.govAggregateData.forEach((group: any) => {
          group.questions.forEach((question: any) => {
            question["chart"] = getChartData.bind(this)(
              question.display_score_data
            );
          });
        });
      });
  }

  getAssessmentAggregateUserResponse() {
    this.additionalInfoLoaded = false;
    this.assessmentCentralService
      .getAggregateResponseDetails(this.currentAssessment)
      .subscribe((response: any) => {
        this.additionalInfoLoaded = true;
        this.assessmentAggregateData = response.group_wise_data;
        this.assessmentAggregateData.forEach((group: any) => {
          group.questions.forEach((question: any) => {
            question["chart"] = getChartData.bind(this)(
              question.display_score_data
            );
          });
        });
      });
  }

  handleDataPointsOnTrendGraphs(isEnabled = false) {
    this.customerData.forEach((dataItem: any) => {
      initializeMetrics.bind(this)();
      const { gdpName, gdpValue } = processActualValues.bind(this)(
        dataItem.value.actual_value
      );
      const lineTrendGrph = this.getLineTrendGraph(
        dataItem.value,
        gdpName,
        gdpValue,
        isEnabled
      );
      dataItem.trend = lineTrendGrph;
    });
    this.financeData.forEach((dataItem: any) => {
      initializeMetrics.bind(this)();
      const { gdpName, gdpValue } = processActualValues.bind(this)(
        dataItem.value.actual_value
      );
      const lineTrendGrph = this.getLineTrendGraph(
        dataItem.value,
        gdpName,
        gdpValue,
        isEnabled
      );
      dataItem.trend = lineTrendGrph;
    });
    this.engineerData.forEach((dataItem: any) => {
      initializeMetrics.bind(this)();
      const { gdpName, gdpValue } = processActualValues.bind(this)(
        dataItem.value.actual_value
      );
      const lineTrendGrph = this.getLineTrendGraph(
        dataItem.value,
        gdpName,
        gdpValue,
        isEnabled
      );
      dataItem.trend = lineTrendGrph;
    });
    this.peopleData.forEach((dataItem: any) => {
      initializeMetrics.bind(this)();
      const { gdpName, gdpValue } = processActualValues.bind(this)(
        dataItem.value.actual_value
      );
      const lineTrendGrph = this.getLineTrendGraph(
        dataItem.value,
        gdpName,
        gdpValue,
        isEnabled
      );
      dataItem.trend = lineTrendGrph;
    });
  }

  async downloadPDF() {
    const page: any = document.getElementById("cardsContainer");
    if (page) {
      this.dataLoaded = false;
      this.handleDataPointsOnTrendGraphs(true);
      page.style.padding = "15px";
      const bscHeader: any = document.getElementById("bscHeader");
      bscHeader.classList.remove("hidden");
      // Prepare bscCards for rendering PDF
      const bscCards: any = page.getElementsByClassName("bsc-card-container");
      for (let bscCard of bscCards) {
        bscCard.classList.add("w-100");
        const tables: any = page.getElementsByClassName("bsc-card");
        for (let table of tables) {
          table.classList.remove("table-responsive");
          table.style.height = "auto";
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      html2canvas(page, {
        scrollY: -window.scrollY,
        useCORS: true,
        scale: 2,
      }).then((canvas) => {
        const imageQuality = 0.7;
        const imgData = canvas.toDataURL("image/jpeg", imageQuality); // Use JPEG, quality set to 0.7
        // Create PDF
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 page width in mm
        const pageHeight = 297; // A4 page height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        // Add content to PDF and handle page breaks
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("scorecard.pdf");
        // Restore the original styles
        bscHeader.classList.add("hidden");
        for (let bscCard of bscCards) {
          bscCard.classList.remove("w-100");
          const tables: any = page.getElementsByClassName("bsc-card");
          for (let table of tables) {
            table.classList.add("table-responsive");
            table.style.height = "310px";
          }
        }
        page.style.padding = "0px";
        this.handleDataPointsOnTrendGraphs();
        this.dataLoaded = true;
      });
    }
  }

  getFormattedValue(val: number) {
    let formattedVal: any = 0;
    if (val) {
      const absVal = Math.abs(val);
      if (absVal >= 1e9) {
        formattedVal = (val / 1e9).toFixed(1) + "B";
      } else if (absVal >= 1e6) {
        formattedVal = (val / 1e6).toFixed(1) + "M";
      } else if (absVal >= 1e3) {
        formattedVal = (val / 1e3).toFixed(1) + "K";
      } else {
        formattedVal = val.toString();
      }
    }
    return formattedVal;
  }

  showEditPulseDialog(pulse: any) {
    const dialog = this.dialog.open(CreatePulseDialogComponent, {
      width: "500px",
      height: "80vh",
      data: {
        pulseId: pulse.id,
      },
    });
    dialog.afterClosed().subscribe((data: any) => {
      if (data) {
        setTimeout(() => {
          getPulsesInfo.bind(this)();
        }, 1000);
      }
    });
  }

  ngOnDestroy() {
    this.programId = null;
    this.portfolioId = null;
    this.subportfolioId = null;
    this.programId = null;
    this.scorecardService.changeMessage("nodata");
    this.scorecardService.okrSpread("");
    this.scorecardService.riskSpread("");
    this.filterService.filterCfgs.next({});
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.filterService.setFilterValue("");
  }

  editWidget(widget: any) {
    this.isCreateWidget = true;
    this.isWidgetEditMode = true;
    this.currentWidget = widget;
    this.widgetCreateForm.patchValue({
      name: widget.name,
      description: widget.description,
    });
    if (this.showRecommendedWidgets) {
      this.widgetCreateForm.disable();
    }
    this.scorecardService.getWidget(widget.id).subscribe((res: any) => {
      this.selectedMetrics = res.metrics;
    });
  }

  getTrendData(freq: any = null, startDate: any = null, endDate: any = null) {
    let qParam = "";
    if (freq) {
      qParam += `&frequency=${freq}`;
    }
    if (startDate) {
      qParam += `&start_date=${startDate}`;
    }
    if (endDate) {
      qParam += `&end_date=${endDate}`;
    }
    this.widgetsLoaded = false;
    this.scorecardService
      .getWidgetTrend(this.currentWidget.id, qParam)
      .subscribe((res: any) => {
        this.widgetsLoaded = true;
        this.widgetTrendData = res;
        this.trendTableColumns =
          this.widgetTrendData?.metrics[0]?.metric_data?.map(
            (val: any) => val.period
          );
        this.trendTableColumns.unshift("Metric");
      });
  }

  openWidget(widget: any) {
    this.isWidgetInfo = true;
    this.showLineChart = false;
    this.currentWidget = widget;
    this.getTrendData();
  }

  initPerformanceWidgets() {
    this.isCreateWidget = false;
    this.isWidgetInfo = false;
    this.currentWidget = null;
    this.isWidgetEditMode = false;
    this.showRecommendedWidgets = false;
    this.selectedMetrics = [];
    this.widgetCreateForm.reset();
  }

  navigateBack() {
    this.initPerformanceWidgets();
    this.getWidgets();
  }

  addWidget() {
    this.isCreateWidget = true;
    this.isWidgetEditMode = false;
  }

  createWidget() {
    let payload = this.widgetCreateForm.value;
    payload["metric_ids"] = this.selectedMetrics.map(
      (metric: any) => metric.id
    );
    if (this.projectId) {
      payload["type"] = "project";
      payload["project_id"] = this.projectId;
    } else if (this.programId) {
      payload["type"] = "program";
      payload["program_id"] = this.programId;
    } else if (this.subportfolioId) {
      payload["type"] = "sub_portfolio";
      payload["sub_portfolio_id"] = this.subportfolioId;
    } else if (this.portfolioId) {
      payload["type"] = "portfolio";
      payload["portfolio_id"] = this.portfolioId;
    } else {
      payload["type"] = "all_of_it";
    }
    this.scorecardService.createWidget(payload).subscribe(() => {
      this.toastrService.success("Widget created successfully");
      setTimeout(() => {
        this.navigateBack();
      }, 500);
    });
  }

  updateWidget() {
    let payload = this.widgetCreateForm.value;
    payload["metric_ids"] = this.selectedMetrics.map(
      (metric: any) => metric.id
    );
    this.widgetsLoaded = false;
    this.scorecardService
      .updateWidget(this.currentWidget.id, payload)
      .subscribe(() => {
        this.toastrService.success("Widget updated successfully");
        this.widgetsLoaded = true;
        setTimeout(() => {
          this.navigateBack();
        }, 500);
      });
  }

  removeMetric(metric: any) {
    this.selectedMetrics = this.selectedMetrics.filter(
      (item: any) => item.id !== metric.id
    );
  }

  getMetrics(): Observable<any> {
    this.widgetsLoaded = false;
    return this.scorecardService.getMetrics().pipe(
      tap((res: any) => {
        this.metricsList = res.data;
        this.widgetsLoaded = true;
      })
    );
  }

  showAddMetricDialog(isProjectLevel = false) {
    this.getMetrics().subscribe(() => {
      const dialog = this.dialog.open(WidgetMetricDialogComponent, {
        width: "500px",
        height: "50vh",
        data: {
          metricsList: this.metricsList,
          selectedMetrics: this.selectedMetrics.map((m: any) => m.id),
          isProjectLevelMetric: isProjectLevel,
        },
      });

      dialog.afterClosed().subscribe((data: any) => {
        if (data) {
          this.selectedMetrics = this.metricsList.filter((metric: any) =>
            data.metrics.includes(metric.id)
          );
          if (isProjectLevel) {
            const payload = {
              project_id: this.projectId,
              metric_id: data.metrics,
            };
            this.dataLoaded = false;
            this.scorecardService
              .addProjectLevelMetrics(payload)
              .subscribe((res: any) => {
                this.toastrService.success("Metrics added successfully");
                getScoreCardQuadrantData.bind(this)();
              });
          }
        }
      });
    });
  }

  toggleView() {
    this.showLineChart = !this.showLineChart;
    if (this.showLineChart) {
      this.trendChartOptions = getTrendChartData(this.widgetTrendData);
    }
  }

  toggleTrendAndPointInTime() {
    this.showTrendView = !this.showTrendView;
    const freq = this.performanceWidgetFilterForm.get("frequency")?.value;
    if (!this.showTrendView) {
      const currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      this.getTrendData(freq, currentDate, currentDate);
    } else {
      this.getTrendData(freq);
    }
  }

  getRecommenededWidgets() {
    this.widgetsLoaded = false;
    this.scorecardService
      .getRecommendedWidgets(this.projectId)
      .subscribe((res: any) => {
        this.widgetsList = res;
        this.addBgColorToWidgets();
        this.widgetsLoaded = true;
      });
  }

  toggleRecommendedWidgets(event: any) {
    this.showRecommendedWidgets = !this.showRecommendedWidgets;
    if (this.showRecommendedWidgets) {
      this.getRecommenededWidgets();
    } else {
      this.getWidgets();
    }
  }

  setupPerformanceWidgetFilters(): void {
    this.performanceWidgetFilterForm.valueChanges.subscribe((values) => {
      this.performSearch(values);
    });
  }

  performSearch(filterValues: any): void {
    this.frequency = filterValues.frequency;
    let startDate = this.datePipe.transform(
      filterValues.startDate,
      "YYYY-MM-dd"
    );
    let endDate = this.datePipe.transform(filterValues.endDate, "YYYY-MM-dd");
    if (!this.showTrendView) {
      const currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      startDate = currentDate;
      endDate = currentDate;
    }
    this.getTrendData(this.frequency, startDate, endDate);
  }

  showDeleteConfirmation(widget: any) {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      data: widget.name,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.scorecardService.deleteWidget(widget.id).subscribe((res: any) => {
          this.toastrService.success("Widget updated successfully");
          setTimeout(() => {
            this.navigateBack();
          }, 500);
        });
      }
    });
  }

  cloneWidget() {
    const payload = {
      project_id: this.projectId,
      widget_id: this.currentWidget.id,
    };
    this.scorecardService.cloneWidget(payload).subscribe((res: any) => {
      this.toastrService.success("Widget cloned successfully");
      setTimeout(() => {
        this.navigateBack();
      }, 500);
    });
  }
}
