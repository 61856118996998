import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GetProposalService } from "../../services/get-proposal.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-tags-mapped-projects",
  templateUrl: "./tags-mapped-projects.component.html",
  styleUrls: ["./tags-mapped-projects.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagMappedProjectsComponent implements OnInit {
  opportunityId: any;
  projects: any = [];
  loading = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly proposalService: GetProposalService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getOpportunityMappedProjects();
    });
  }

  getOpportunityMappedProjects() {
    this.loading = true;
    this.proposalService
      .getMappedProjects(this.opportunityId)
      .subscribe((res: any) => {
        this.loading = false;
        this.projects = res;
        this.projects.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });
      });
  }

  getGMapRandomColor() {
    const colorNum = 360;
    const color = Math.floor(Math.random() * colorNum);
    return `hsla(${color}, 30%, 75%, 100%)`;
  }

  openProjects(res: any) {
    const newitem = {
      project_id: res.id,
      program_name: res.projectprograms__program__name,
      program_id: res.projectprograms__program,
      subportfolio_name: res.sub_portfolio,
      subportfolio_id: res.sub_portfolio_id,
      portfolio_name: res.portfolio,
      portfolio_id: res.portfolio_id,
      manager: res.owner_name,
    };
    localStorage.setItem("program", JSON.stringify(newitem));

    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        opportunity: res.id,
      },
    });
    dialog.componentInstance.type = { name: "projectpop" };
    dialog.afterClosed().subscribe((data: any) => {
      if (data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }
}
